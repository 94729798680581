var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left" }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-12 gap-x-4 py-2" },
      [
        _c("div", { staticClass: "col-span-5 my-auto" }, [
          _c("div", [
            _c("span", { staticClass: "font-bold mr-2" }, [
              _vm._v(_vm._s(_vm.fileOutline) + ":"),
            ]),
            _c("span", [_vm._v(_vm._s(_vm.fileDescription))]),
          ]),
        ]),
        _vm.fileStruct &&
        ((Object.keys(_vm.fileStruct).includes("video") &&
          _vm.fileStruct.video.file.file) ||
          (Object.keys(_vm.fileStruct).includes("subtitle") &&
            _vm.fileStruct.subtitle &&
            _vm.fileStruct.subtitle.file))
          ? [
              _vm._m(0),
              _c("div", { staticClass: "col-span-2" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-full px-1 py-2 bg-base-yellow font-bold focus:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                    on: {
                      click: function ($event) {
                        return _vm.downloadRemoteFile()
                      },
                    },
                  },
                  [_vm._v(" ダウンロード ")]
                ),
              ]),
            ]
          : _vm._e(),
        _vm._l(_vm.imageStructs, function (struct, imageIndex) {
          return [
            _c(
              "div",
              {
                key: "uploaded-" + imageIndex,
                staticClass:
                  "col-start-6 col-span-3 border border-brown-gray relative h-10",
              },
              [_vm._m(1, true)]
            ),
            _c(
              "div",
              { key: "download-" + imageIndex, staticClass: "col-span-2" },
              [
                _c(
                  "button",
                  {
                    staticClass:
                      "w-full px-1 py-2 bg-base-yellow font-bold focus:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                    on: {
                      click: function ($event) {
                        return _vm.downloadRemoteFile(imageIndex)
                      },
                    },
                  },
                  [_vm._v(" ダウンロード ")]
                ),
              ]
            ),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "col-start-6 col-span-3 border border-brown-gray relative h-10",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "bg-light-green absolute left-0 top-0 bottom-0 right-0 w-full h-full text-center text-white",
          },
          [
            _c(
              "span",
              {
                staticClass:
                  "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full",
              },
              [_vm._v("アップロード済み")]
            ),
          ]
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "bg-light-green absolute left-0 top-0 bottom-0 right-0 w-full h-full text-center text-white",
      },
      [
        _c(
          "span",
          {
            staticClass:
              "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full",
          },
          [_vm._v("アップロード済み")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }