var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TermsItem", {
        attrs: { title: _vm.$t("pages.commons.quotes.proposed_amount") },
        scopedSlots: _vm._u([
          {
            key: "tagfield",
            fn: function () {
              return [
                _c("div", [
                  _c("label", [
                    _vm.baseQuote.pricing_type === 2
                      ? _c(
                          "span",
                          [
                            _vm.loginUserRole === "provider"
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "commons.pricing_type.revenue_share"
                                        )
                                      ) +
                                      ":" +
                                      _vm._s(
                                        (_vm.baseQuote.revenue_percent *
                                          (100 - _vm.quoteItem.fee_rate)) /
                                          100
                                      ) +
                                      "% "
                                  ),
                                ]
                              : _vm.loginUserRole === "consumer"
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "commons.pricing_type.revenue_share"
                                        )
                                      ) +
                                      ":" +
                                      _vm._s(_vm.baseQuote.revenue_percent) +
                                      "% "
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm.baseQuote.pricing_type === 1
                      ? _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("pages.commons.quotes.decided_payment")
                              ) +
                              ":" +
                              _vm._s(_vm.quotePrice.total) +
                              " "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _vm.hasMG(_vm.baseQuote, _vm.quoteItem, _vm.loginUserRole)
                  ? _c("div", [
                      _c("label", [
                        _c(
                          "span",
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "pages.commons.quotes.price_minimum_guarantee"
                                  )
                                ) +
                                ": "
                            ),
                            _vm.loginUserRole === "consumer"
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.consumerMGPriceString(
                                          _vm.baseQuote,
                                          _vm.quoteItem
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.providerMGPriceString(
                                          _vm.baseQuote,
                                          _vm.quoteItem
                                        )
                                      ) +
                                      " "
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._l(_vm.quoteItem.formats, function (format, formatIndex) {
        return [
          format.video_type === 1 && format.is_dcp
            ? _c("TermsItem", {
                key: "format-" + formatIndex,
                attrs: {
                  title:
                    _vm.$t("pages.commons.quotes.formats") +
                    "(" +
                    _vm.videoTypeName(format) +
                    ")",
                  outline: "DCP",
                },
              })
            : _c("TermsItem", {
                key: "format-" + formatIndex,
                attrs: {
                  title:
                    _vm.$t("pages.commons.quotes.formats") +
                    "(" +
                    _vm.videoTypeName(format) +
                    ")",
                  outline: _vm.videoFormatOutline(format),
                },
              }),
        ]
      }),
      _c("TermsItem", {
        attrs: { title: _vm.$t("pages.commons.quotes.subtitle_language") },
        scopedSlots: _vm._u([
          {
            key: "tagfield",
            fn: function () {
              return [
                _vm.quoteItem.languages.find(function (language) {
                  return language.is_subtitle || language.is_voiceover
                }) !== undefined
                  ? _vm._l(
                      _vm.quoteItem.languages,
                      function (language, languageIndex) {
                        return _c(
                          "div",
                          {
                            key: "language-" + languageIndex,
                            staticClass: "mb-1",
                          },
                          [
                            _c(
                              "label",
                              { staticClass: "table-cell align-middle" },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.languageTitle(language)) +
                                      ": " +
                                      _vm._s(_vm.languageOutline(language))
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }
                    )
                  : [
                      _c("label", { staticClass: "table-cell align-middle" }, [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "sheets.delivery_terms.cell_value.no_subtitle"
                                )
                              ) +
                              " "
                          ),
                        ]),
                      ]),
                    ],
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.quoteItem.countries.length || _vm.quoteItem.all_countries
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("pages.commons.quotes.distribution_area"),
              outline: _vm.countriesOutline(),
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "tagfield",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex justify-start flex-wrap content-around",
                        },
                        [
                          _vm._l(
                            _vm.quoteItem.countries,
                            function (country, key) {
                              return [
                                _c(
                                  "div",
                                  {
                                    key: "country-" + key,
                                    staticClass:
                                      "py-1 px-2 mr-2 mb-2 genre-tag-label text-sm text-white",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.getCountryName(country)) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "span",
                        { staticClass: "text-xs bg-off-yellow-lighter p-1" },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$i18n.t("pages.commons.quotes.territory_note")
                            )
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2017543370
            ),
          })
        : _vm._e(),
      _vm.quoteItem.rights
        ? _c("TermsItem", {
            attrs: { title: _vm.$t("pages.commons.quotes.rights") },
            scopedSlots: _vm._u(
              [
                {
                  key: "tagfield",
                  fn: function () {
                    return _vm._l(
                      _vm.quoteItem.rights,
                      function (right, rightsIndex) {
                        return _c(
                          "div",
                          {
                            key: "rights-" + rightsIndex,
                            staticClass: "grid grid-cols-12 gap-6",
                          },
                          [
                            _c("div", { staticClass: "col-span-3 table" }, [
                              _c("label", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.rightsNames[right.right_type - 1]
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ]),
                            right.exclusive_type == 1
                              ? _c(
                                  "div",
                                  { staticClass: "col-span-2 table h-full" },
                                  [
                                    _c("div", [
                                      _c("span", { staticClass: "inline" }, [
                                        _c(
                                          "label",
                                          { staticClass: "text-red-600" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "pages.commons.quotes.exclusive"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : right.exclusive_type == 2
                              ? _c(
                                  "div",
                                  { staticClass: "col-span-2 table h-full" },
                                  [
                                    _c("span", [
                                      _c("label", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pages.commons.quotes.nonexclusive"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : right.exclusive_type == 3
                              ? _c(
                                  "div",
                                  { staticClass: "col-span-2 table h-full" },
                                  [
                                    _c("span", [
                                      _c("label", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "pages.commons.quotes.holdback"
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            right.exclusive_type == 3 && right.holdback_length
                              ? _c(
                                  "div",
                                  { staticClass: "col-span-2 table h-full" },
                                  [
                                    _c("span", [
                                      _c("label", {}, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.textOfEnum(
                                              _vm.rightHoldbackLength,
                                              right.holdback_length
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        )
                      }
                    )
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2085871235
            ),
          })
        : _vm._e(),
      _vm.quoteItem.channels
        ? _c("TermsItem", {
            attrs: { title: _vm.$t("commons.fields.channels") },
            scopedSlots: _vm._u(
              [
                {
                  key: "tagfield",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "grid grid-cols-12 gap-x-6" },
                        _vm._l(
                          _vm.quoteItem.channels,
                          function (channel, index) {
                            return _c(
                              "label",
                              {
                                key: "channel-" + index,
                                staticClass: "col-span-8",
                              },
                              [
                                _c("span", { staticClass: "mr-2" }, [
                                  _vm._v(_vm._s(channel.name)),
                                ]),
                                _c("span", [
                                  _vm._v("[" + _vm._s(channel.url) + "]"),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1414915421
            ),
          })
        : _vm._e(),
      _c("TermsItem", {
        attrs: { title: _vm.$t("pages.commons.quotes.offline_viewing") },
        scopedSlots: _vm._u([
          {
            key: "tagfield",
            fn: function () {
              return [
                _c("div", { staticClass: "grid grid-cols-12 gap-x-6" }, [
                  _vm.quoteItem.allow_offline_viewing
                    ? _c("span", [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("commons.fields.allow"))),
                        ]),
                      ])
                    : _c("span", [
                        _c("label", [
                          _vm._v(_vm._s(_vm.$t("commons.fields.deny"))),
                        ]),
                      ]),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.contractPeriod(_vm.quoteItem)
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.planned_streaming_period"),
              outline: _vm.contractPeriod(_vm.quoteItem),
            },
          })
        : _vm._e(),
      _c("TermsItem", {
        attrs: {
          title: _vm.$t("commons.fields.actual_streaming_period"),
          outline: _vm.actualContractPeriod(_vm.quoteItem),
        },
      }),
      _vm.quoteItem.screenshot_format || _vm.quoteItem.screenshot_count
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.screenshot"),
              outline:
                "" +
                _vm.quoteItem.screenshot_count +
                _vm.$t("pages.commons.quotes.count") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.screenshot_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.screenshot_size),
            },
          })
        : _vm._e(),
      _vm.quoteItem.poster_visual_format
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.poster_visual"),
              outline:
                _vm.$t("commons.fields.required") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.poster_visual_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.poster_visual_size) +
                _vm.requestLayeredString(
                  _vm.quoteItem.poster_visual_is_layered
                ),
            },
          })
        : _vm._e(),
      _vm.quoteItem.logo_visual_format
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.logo_visual"),
              outline:
                _vm.$t("commons.fields.required") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.logo_visual_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.logo_visual_size) +
                _vm.requestLayeredString(_vm.quoteItem.logo_visual_is_layered),
            },
          })
        : _vm._e(),
      _vm.quoteItem.key_art_format
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.key_art"),
              outline:
                _vm.$t("commons.fields.required") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.key_art_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.key_art_size) +
                _vm.requestLayeredString(_vm.quoteItem.key_art_is_layered),
            },
          })
        : _vm._e(),
      _vm.quoteItem.fonts && _vm.quoteItem.fonts.length
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.font_file"),
              outline:
                _vm.$t("commons.fields.required") +
                "[" +
                _vm.textOfEnum(
                  _vm.fontFileFormat,
                  _vm.quoteItem.fonts[0].movie_font.format,
                  false
                ) +
                "]",
            },
          })
        : _vm._e(),
      _vm.quoteItem.making_stills_count
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.making_stills"),
              outline:
                "" +
                _vm.quoteItem.making_stills_count +
                _vm.$t("pages.commons.quotes.count") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.making_visual_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.making_stills_size),
            },
          })
        : _vm._e(),
      _vm.quoteItem.laurels_format
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.laurels"),
              outline:
                _vm.$t("commons.fields.required") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.laurels_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.laurels_size),
            },
          })
        : _vm._e(),
      _vm.quoteItem.banner_imagery_format
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.banner"),
              outline:
                _vm.$t("commons.fields.required") +
                "[" +
                _vm.fileFormatString(_vm.quoteItem.banner_imagery_format) +
                "] " +
                _vm.fileSizeString(_vm.quoteItem.banner_imagery_size) +
                _vm.requestLayeredString(
                  _vm.quoteItem.banner_imagery_is_layered
                ),
            },
          })
        : _vm._e(),
      _vm._l(_vm.quoteItem.social_medias, function (media, index) {
        return _c("TermsItem", {
          key: "socialmedia-" + index,
          attrs: {
            title: _vm.textOfEnum(
              _vm.socialMediaTypes,
              media.social_media_type
            ),
            outline: _vm.$t("commons.fields.required"),
          },
        })
      }),
      _vm._l(_vm.quoteItem.documents, function (document, index) {
        return _c("TermsItem", {
          key: "document-" + index,
          attrs: {
            title: _vm.textOfEnum(
              _vm.movieDocument,
              document.movie_document.document_type
            ),
            outline:
              _vm.$t("commons.fields.required") +
              "[" +
              _vm.textOfEnum(
                _vm.documentFormat,
                document.movie_document.format,
                false
              ) +
              "]",
          },
        })
      }),
      _vm.deliveryBy(_vm.quoteItem)
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("commons.fields.delivery_possible_by"),
              outline: _vm.deliveryBy(_vm.quoteItem),
            },
          })
        : _vm._e(),
      _vm.translatedMessage
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("sheets.license_agreement.columns.materials_note"),
              outline: _vm.translatedMessage,
            },
          })
        : _vm._e(),
      _vm.translatedContractTerms
        ? _c("TermsItem", {
            attrs: {
              title: _vm.$t("sheets.license_agreement.columns.conditions_note"),
              outline: _vm.translatedContractTerms,
            },
          })
        : _vm._e(),
      _vm.loginUserRole !== "consumer"
        ? _c("TermsItem", {
            attrs: {
              title: "「Cueシート」の提出について",
              outline:
                "JASRAC管理楽曲を納品される映画で使用されている場合、当該楽曲の「Cueシート（ミュージックキューシート）」を、納品モジュールの「その他」にUPしてください。",
            },
          })
        : _vm._e(),
      _vm.loginUserRole !== "consumer" && _vm.quoteItem.documents.length
        ? _c("TermsItem", {
            attrs: {
              title: "ドキュメント素材の納品について",
              outline:
                "ドキュメント素材（例：あらすじ、キャッチフレーズ、ログラインなど）は、このページの下部にある納品モジュールの「その他」にUPしてください。",
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "w-full bg-gray-200" },
        [
          _vm.isImpersonated
            ? _c("TermsItem", {
                attrs: {
                  title: _vm.$t("pages.commons.quotes.delivery_method"),
                  outline: _vm.deliveryOutline(),
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }