var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "grid grid-cols-6 gap-4" },
    [
      _c("div", { staticClass: "col-span-2" }, [
        _vm.explanation && _vm.explanation.length
          ? _c("span", [_vm._v(_vm._s(_vm.explanation))])
          : _vm._e(),
      ]),
      _vm._l(_vm.valueArray, function (item, index) {
        return [
          _c(
            "div",
            {
              key: "uploaded-download-" + index,
              staticClass: "col-span-1 col-start-5",
            },
            [
              _vm.isImpersonated || _vm.userIsConsumer
                ? _c(
                    "button",
                    {
                      staticClass:
                        "w-full px-1 py-2 h-10 bg-base-yellow font-bold rounded active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                      on: {
                        click: function ($event) {
                          return _vm.downloadRemoteFile(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("pages.commons.quotes.download")) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              key: "uploaded-delete-" + index,
              staticClass: "col-span-1 col-start-6",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "w-full px-1 py-2 h-10 bg-base-yellow font-bold rounded active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                  on: {
                    click: function ($event) {
                      return _vm.deleteRemoteFile(item)
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("pages.commons.quotes.delete")) + " "
                  ),
                ]
              ),
            ]
          ),
        ]
      }),
      _vm._l(_vm.uploadings, function (uploading, upIndex) {
        return [
          _c(
            "div",
            {
              key: "upload-status-" + upIndex,
              staticClass: "col-span-2 col-start-3 bg-gray-100 relative h-10",
            },
            [
              _c("div", {
                staticClass:
                  "absolute left-0 top-0 bottom-0 m-auto bg-light-green",
              }),
              _c(
                "span",
                {
                  staticClass:
                    "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center",
                  class: uploading.aborted ? "line-through" : "",
                },
                [_vm._v(" " + _vm._s(uploading.progressText) + " ")]
              ),
            ]
          ),
          _c(
            "div",
            {
              key: "upload-control-pause-" + upIndex,
              staticClass: "col-span-1 col-start-5",
            },
            [
              !uploading.aborted
                ? _c(
                    "button",
                    {
                      staticClass:
                        "w-full px-1 py-2 h-10 bg-base-yellow font-bold rounded active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                      attrs: { disabled: uploading.pauseButtonDisable },
                      on: {
                        click: function ($event) {
                          return _vm.pauseUpload(_vm.uploadingIndex)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(uploading.pauseButtonTitle) + " ")]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              key: "upload-control-stop-" + upIndex,
              staticClass: "col-span-1 col-start-6",
            },
            [
              _c(
                "button",
                {
                  staticClass:
                    "w-full px-1 py-2 h-10 bg-base-yellow font-bold rounded active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                  attrs: { disabled: uploading.abortButtonDisable },
                  on: {
                    click: function ($event) {
                      return _vm.abortUpload(_vm.uploadingIndex)
                    },
                  },
                },
                [_vm._v(" " + _vm._s(uploading.abortButtonTitle) + " ")]
              ),
            ]
          ),
        ]
      }),
      _vm.valueArray.length < _vm.uuidArray.length
        ? _c(
            "div",
            { staticClass: "col-span-4 col-start-3" },
            [
              _c(
                "FlCheckbox",
                {
                  model: {
                    value: _vm.verifiedFile,
                    callback: function ($$v) {
                      _vm.verifiedFile = $$v
                    },
                    expression: "verifiedFile",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("pages.commons.quotes.check_file_format"))
                  ),
                ]
              ),
              _vm.verifiedFile
                ? _c("FlDnD", {
                    attrs: { extensions: _vm.calculatedExtensions },
                    on: { input: _vm.fileDropped },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c("NoticeModal", {
        attrs: {
          modalName: _vm.modalDialogName,
          title: _vm.$t("pages.commons.quotes.delete_etc_description", {
            name: _vm.fileTypeText,
          }),
          buttons: [_vm.$t("pages.commons.quotes.cancel"), "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.deleteFile(_vm.removeIndex)
          },
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: _vm.modalDeleteDialogName,
          title: _vm.$t("pages.commons.quotes.delete_movie_result", {
            name: _vm.fileTypeText,
          }),
          buttons: ["OK"],
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }