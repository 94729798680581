var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-left" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-12 gap-x-4 py-2" },
        [
          _c("div", { staticClass: "col-span-5 my-auto" }, [
            _c("div", [
              _c("span", { staticClass: "font-bold mr-2" }, [
                _vm._v(_vm._s(_vm.fileOutline) + ":"),
              ]),
              _c("span", [_vm._v(_vm._s(_vm.fileDescription))]),
            ]),
          ]),
          _vm.fileStruct &&
          ((Object.keys(_vm.fileStruct).includes("video") &&
            _vm.fileStruct.video.file.file) ||
            (Object.keys(_vm.fileStruct).includes("subtitle") &&
              _vm.fileStruct.subtitle &&
              _vm.fileStruct.subtitle.file))
            ? [
                _c(
                  "div",
                  { staticClass: "col-start-6 col-span-3 h-10 relative" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "bg-light-green border border-brown-gray absolute left-0 top-0 bottom-0 right-0 text-center text-white",
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full",
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("pages.commons.quotes.uploaded")
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm.isImpersonated || _vm.userIsProvider
                  ? _c("div", { staticClass: "col-span-2" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "w-full px-1 py-2 h-10 bg-base-yellow font-bold active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                          on: {
                            click: function ($event) {
                              return _vm.deleteRemoteFile()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("pages.commons.quotes.delete")) +
                              " "
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-span-2" }, [
                  _vm.isImpersonated || _vm.userIsConsumer
                    ? _c(
                        "button",
                        {
                          staticClass:
                            "w-full px-1 py-2 h-10 bg-base-yellow font-bold active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                          on: {
                            click: function ($event) {
                              return _vm.downloadRemoteFile()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("pages.commons.quotes.download")) +
                              " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            : _vm._e(),
          _vm._l(_vm.imageStructs, function (struct, imageIndex) {
            return [
              struct.movie_image.image_type === _vm.fileTypeNumber &&
              struct.movie_image.file !== null
                ? [
                    _c(
                      "div",
                      {
                        key: "uploaded-" + imageIndex,
                        staticClass: "col-start-6 col-span-3 relative h-10",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "bg-light-green border border-brown-gray absolute left-0 top-0 bottom-0 right-0 text-center text-white",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full",
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.commons.quotes.uploaded")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm.isImpersonated || _vm.userIsProvider
                      ? _c(
                          "div",
                          {
                            key: "delete-" + imageIndex,
                            staticClass: "col-span-2",
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "w-full px-1 py-2 bg-base-yellow font-bold active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteRemoteFile(imageIndex)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.commons.quotes.delete")
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        key: "download-" + imageIndex,
                        staticClass: "col-span-2",
                      },
                      [
                        _vm.isImpersonated || _vm.userIsConsumer
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "w-full px-1 py-2 bg-base-yellow font-bold active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed border border-white",
                                on: {
                                  click: function ($event) {
                                    return _vm.downloadRemoteFile(imageIndex)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("pages.commons.quotes.download")
                                    ) +
                                    " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                : _vm._e(),
            ]
          }),
          _vm._l(_vm.uploadings, function (uploading, uploadingIndex) {
            return [
              _c(
                "div",
                {
                  key: "progress-" + uploadingIndex,
                  staticClass:
                    "col-start-6 col-span-3 border border-brown-grey relative h-10",
                },
                [
                  _c("div", {
                    staticClass: "absolute left-0 top-0 bottom-0 m-auto",
                    class: uploading.aborted
                      ? "bg-brown-grey"
                      : "bg-light-yellow",
                    style: { width: uploading.uploadProgress + "%" },
                  }),
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-bold absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full text-center",
                      class: uploading.aborted ? "line-through" : "",
                    },
                    [_vm._v(" " + _vm._s(uploading.progressText) + " ")]
                  ),
                ]
              ),
              _vm.uploadVersion === "v2"
                ? [
                    _c(
                      "div",
                      {
                        key: "pause-" + uploadingIndex,
                        staticClass: "col-span-2",
                      },
                      [
                        !uploading.aborted
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "w-full px-1 py-2 bg-base-yellow font-bold active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                                attrs: {
                                  disabled: uploading.pauseButtonDisable,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.pauseUpload(uploadingIndex)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(uploading.pauseButtonTitle) + " "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        key: "abort-" + uploadingIndex,
                        staticClass: "col-span-2 h-10",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass:
                              "w-full px-1 py-2 bg-base-yellow font-bold active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
                            attrs: { disabled: uploading.abortButtonDisable },
                            on: {
                              click: function ($event) {
                                return _vm.abortUpload(uploadingIndex)
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(uploading.abortButtonTitle) + " "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                : _vm._e(),
            ]
          }),
          _vm.stillCanUpload && !_vm.userIsConsumer
            ? [
                _c(
                  "div",
                  { staticClass: "col-start-1 col-span-6 mb-2" },
                  [
                    _c(
                      "FlCheckbox",
                      {
                        model: {
                          value: _vm.enabled,
                          callback: function ($$v) {
                            _vm.enabled = $$v
                          },
                          expression: "enabled",
                        },
                      },
                      [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("pages.commons.quotes.check_file_format")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-start-1 col-span-12" },
                  [
                    _c("FlDnD", {
                      attrs: {
                        extensions: _vm.calculatedExtensions,
                        disabled: !_vm.enabled,
                      },
                      on: { input: _vm.fileDropped },
                    }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
        ],
        2
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: _vm.modalDialogName,
          title: _vm.$t("pages.commons.quotes.delete_etc_description", {
            name: _vm.getFileType(_vm.removeIndex),
          }),
          buttons: [_vm.$t("pages.commons.quotes.cancel"), "OK"],
        },
        on: {
          click: function ($event) {
            return _vm.deleteFile(_vm.removeIndex)
          },
        },
      }),
      _c("NoticeModal", {
        attrs: {
          modalName: _vm.modalDeleteDialogName,
          title: _vm.$t("pages.commons.quotes.delete_movie_result", {
            name: _vm.lastdeleteType,
          }),
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }