var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "py-2 border boder-b-0 border-very-light-grey",
        on: {
          click: function ($event) {
            _vm.isOpen = !_vm.isOpen
          },
        },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "w-full flex items-center justify-between cursor-pointer",
          },
          [
            _vm.notDisplayItem.indexOf("header-icon") < 0
              ? _c("div", { staticClass: "w-6/12 flex justify-start" }, [
                  _vm.quoteItem.inputStatus
                    ? _c("img", {
                        staticClass: "w-6 h-6 mx-2",
                        attrs: {
                          src: require("@/assets/image/alert_icon.svg"),
                        },
                      })
                    : _c("img", {
                        staticClass: "w-6 h-6 mx-2",
                        attrs: { src: require("@/assets/image/ok_icon.svg") },
                      }),
                  _c("span", { staticClass: "mx-2 text-lg" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.quoteMovieName) +
                        " - " +
                        _vm._s(
                          _vm.$t(
                            "pages.commons.quotes." +
                              _vm.quoteMovieState(
                                _vm.quoteItem,
                                _vm.loginUserRole
                              )
                          )
                        ) +
                        " "
                    ),
                  ]),
                ])
              : _c("div", { staticClass: "w-6/12 flex justify-start" }, [
                  _c("span", { staticClass: "ml-2 text-lg" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.quoteMovieName) +
                        " - " +
                        _vm._s(
                          _vm.$t(
                            "pages.commons.quotes." +
                              _vm.quoteMovieState(
                                _vm.quoteItem,
                                _vm.loginUserRole
                              )
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]),
            _c("div", { staticClass: "w-6/12 flex justify-end" }, [
              _vm.notDisplayItem.indexOf("date") < 0
                ? _c("p", { staticClass: "mr-6" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("pages.commons.quotes.delivery_period")) +
                        ": " +
                        _vm._s(_vm._f("date")(_vm.quoteItem.delivery_at)) +
                        " "
                    ),
                  ])
                : _vm._e(),
              _vm.notDisplayItem.indexOf("amount") < 0
                ? _c("p", { staticClass: "mr-6" }, [_vm._v("$800.00USD")])
                : _vm._e(),
              _vm.notDisplayItem.indexOf("trash") < 0
                ? _c(
                    "button",
                    {
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.trashQuote()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "w-6 h-6 mx-2",
                        attrs: {
                          src: require("@/assets/image/trash_icon_grey.svg"),
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm.isOpen
                ? _c("img", {
                    staticClass: "w-6 h-6 mx-2",
                    attrs: {
                      src: require("@/assets/image/close_field_icon.svg"),
                    },
                  })
                : _c("img", {
                    staticClass: "w-6 h-6 mx-2",
                    attrs: {
                      src: require("@/assets/image/open_field_icon.svg"),
                    },
                  }),
            ]),
          ]
        ),
        _c(
          "div",
          { staticClass: "w-full cursor-pointer" },
          [_vm._t("amount")],
          2
        ),
      ]
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isOpen,
            expression: "isOpen",
          },
        ],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }