var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-left mb-3" }, [
    _c("div", { staticClass: "grid grid-cols-12 gap-6" }, [
      _c("p", { staticClass: "col-span-3 text-right" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm.checkedIcon
        ? _c("p", { staticClass: "col-span-8" }, [
            _c("img", {
              staticClass: "w-5 h-5 inline-block mr-2",
              attrs: { src: require("@/assets/image/check_circle_icon.svg") },
            }),
            _c("span", { staticClass: "font-bold" }, [
              _vm._v(_vm._s(_vm.outline)),
            ]),
          ])
        : _c(
            "div",
            { staticClass: "col-span-9" },
            [_c("span", [_vm._v(_vm._s(_vm.outline))]), _vm._t("tagfield")],
            2
          ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }